import {
  DropdownItem,
  MenuButtonPrimary,
} from '@pages/dashboard/you/you-page.styles';
import {ArrowHeadDownIcon} from '@ui/atoms/icons';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {Body2, Headline2} from '@ui/atoms/typography';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import {TabNavigation} from '@ui/molecules/tab-nav';
import {FlexRowSpaceBetween} from '@ui/style/styles';
import PageLayoutTemplate from '@ui/templates/page-template/page-layout';
import {useCallback, useMemo, useState} from 'react';
import {useNavigate} from 'react-router';
import styled from 'styled-components';
import {useQuery} from 'react-query';
import {usePaginationHook} from '@hooks/pagination-hook';
import {useSearchParams} from 'react-router-dom';
import {useUpcomingMeetingHook} from '../individual-meetings/individual-hook';
import {MeetingsTab} from '../individual-meetings/tabs/meetings';
import {ActionItemsTab} from '../individual-meetings/tabs/action-items';
import {Notes} from '../individual-meetings/tabs/notes';
import {authStore} from '@store/stores/auth-store';
import {YearRange} from '@utils/date';

export const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media (max-width: 768px) {
    padding: 0 16px;
  }
  .form {
    width: 70%;

    margin-top: 40px;
    margin-bottom: 30px;
    @media (min-width: 1100px) and (max-width: 1320px) {
      width: 70%;
    }
    @media (min-width: 768px) and (max-width: 1100px) {
      width: 70%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const ViewTeamMeetings = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [activeTab, setActiveTab] = useState<
    'meetings' | 'notes' | 'action_items'
  >('meetings');

  const isTabActive = useCallback((tab: string) => activeTab === tab, [
    activeTab,
  ]);

  const pagination = usePaginationHook();

  const navigations = useMemo(() => {
    const nav = [
      {
        active: isTabActive('meetings'),
        href: '#',
        id: '1',
        onClick: () => {
          setActiveTab('meetings');
        },
        title: 'Meetings',
      },
      {
        active: isTabActive('notes'),
        href: '#',

        id: '2',
        onClick: () => {
          setActiveTab('notes');
        },
        title: 'Notes',
      },
      {
        active: isTabActive('action_items'),
        href: '#',
        id: '3',
        onClick: () => {
          setActiveTab('action_items');
        },
        title: 'Action Items',
      },
    ];

    return nav;
  }, [isTabActive]);

  const meetingCode = searchParams.get('meetingCode');

  const labelTag = searchParams.get('tag');

  const getPeriod = useMemo(() => YearRange(), []);

  const [dateRange, setDateRange] = useState(getPeriod);

  const {getMeetings} = useUpcomingMeetingHook();

  const filter = {
    startDate: dateRange.starts,
    endDate: dateRange.ends,
    meetingCode: meetingCode || undefined,
    label: labelTag || undefined,
    limit: pagination.pages.limit,
    page: pagination.pages.page,
  };

  const {
    data = {
      limit: pagination.pages.limit,
      results: [],
      totalPages: pagination.pages.totalPages,
      totalResults: pagination.pages.totalResults,
      page: pagination.pages.page,
    },
    isLoading,
    refetch,
  } = useQuery(
    ['tagged-meetings', filter, pagination.pages.limit, pagination.pages.page],
    () => getMeetings(filter),
  );

  const meeting = data.results?.[0];

  const participant = meeting?.participant[0];

  const participantIds = meeting?.participant.map(
    (_participant: {id: any}) => _participant.id,
  );

  return (
    <PageLayoutTemplate
      onClick={() => navigate(-1)}
      title="Meetings"
      bodyStyle={{margin: 0}}>
      <FormWrapper>
        <div className="form">
          <FlexRowSpaceBetween>
            <div>
              <div>
                <Headline2 weight="bold">{labelTag}</Headline2>
              </div>

              <VerticalSpacer size="8px" />

              <Body2 kind="textBody">
                View meeting summaries and takeaways.
              </Body2>
            </div>

            <DropdownCustom
              collapseOnClick={true}
              menu={(handleClose: () => void) => (
                <div>
                  <DropdownItem
                    onClick={() => {
                      navigate(`/create-1:1`);
                      handleClose();
                    }}>
                    Schedule meeting
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      navigate(`/create-meeting-notes`);
                      handleClose();
                    }}>
                    Add meeting notes
                  </DropdownItem>
                </div>
              )}
              customComponent={(
                handleOpen: (event: any) => void,
                open: boolean,
              ) => (
                <MenuButtonPrimary onClick={handleOpen} className="px-6 py-3">
                  Create
                  <ArrowHeadDownIcon
                    style={{
                      marginLeft: 14,
                      stroke: '#fff',
                      transform: !open ? 'rotate(0deg)' : 'rotate(180deg)',
                    }}
                  />
                </MenuButtonPrimary>
              )}
              customDropdownWrapperStyles={{
                top: '130%',
                right: '0px',
                width: '140%',
              }}
            />
          </FlexRowSpaceBetween>
          <VerticalSpacer size="24px" />

          <TabNavigation navigations={navigations} type="button" />

          <VerticalSpacer size="24px" />

          {isTabActive('meetings') && (
            <MeetingsTab
              data={data}
              {...pagination}
              dateRange={dateRange}
              isLoading={isLoading}
              refetch={refetch}
              updateDateRange={(value) => setDateRange(value)}
            />
          )}

          {isTabActive('action_items') && (
            <ActionItemsTab
              userId={[...participantIds, authStore.auth.user.id]}
              meetings={data?.results || []}
            />
          )}

          {isTabActive('notes') && (
            <Notes meetings={data?.results || []} userId={participant?.id} />
          )}
        </div>
      </FormWrapper>
    </PageLayoutTemplate>
  );
};
