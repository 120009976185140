import {VerticalSpacer} from '@ui/atoms/spacer';
import {Body1, Body2, Headline3} from '@ui/atoms/typography';
import {PageLayout} from '../member-profile-style';
import styled from 'styled-components';
import {SectionCard} from '@ui/layouts/section-card';
import {FlexRowSpaceBetween} from '@ui/style/styles';
import {useNotification} from './hooks/useNotification';
import {ToggleSwitch} from '@ui/atoms/toggle-switch';
import {Checkbox} from '@ui/atoms/checkbox';
import {List} from '@ui/atoms/list';
import {ListKind} from '@ui/atoms/list/list';

type Props = {};
const NotificationWrapper = styled.div`
  border: 1px solid #ededf2;
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
  .lists {
    border-left: 1px solid #ededf2;
    background: #fafafa;
    padding: 20px 24px;
    width: 50%;
  }
  .desc {
    padding: 20px 24px;
    width: 50%;
  }
`;

const NotificationSettings = (props: Props) => {
  const {options, updateParameters, updateSettings} = useNotification();

  return (
    <div>
      <PageLayout>
        <SectionCard
          CustomHeaderTitle={
            <div>
              <Headline3>Notification settings</Headline3>
              <VerticalSpacer size="8px" />
              <Body2 kind="textBody">
                Select the kind of notifications you get your activities
              </Body2>
            </div>
          }
          variant="columnStart"
          action={<div></div>}>
          <div>
            {options.map((notif, idx) => (
              <div key={idx}>
                <NotificationWrapper>
                  <div className="desc">
                    <FlexRowSpaceBetween>
                      <div>
                        <Headline3>{notif.title}</Headline3>
                        <VerticalSpacer size="8px" />
                        <Body2 kind="textBody">{notif.subTitle}</Body2>
                      </div>
                      <div>
                        <ToggleSwitch
                          switchId={idx}
                          checked={notif.isChecked}
                          onChange={() => updateSettings(notif.type as any)}
                        />
                      </div>
                    </FlexRowSpaceBetween>
                  </div>
                  <div className="lists">
                    {notif.subCategory.map((category, index, categories) => (
                      <div>
                        <FlexRowSpaceBetween style={{alignItems: 'flex-start'}}>
                          <div>
                            <Body1 kind="textBody">{category.name}</Body1>
                            {index === categories.length - 1 && (
                              <div className="mt-1">
                                <Body2 kind="textBody">
                                  When you follow an action item on Beam, you'll
                                  receive notifications for the following
                                  updates:
                                </Body2>
                                <List
                                  kind={ListKind.DISC}
                                  items={[
                                    'Marked as complete.',
                                    'Action item is moved to another list.',
                                    ' Adding and changing due date.',
                                    'Adding link or URL to action items.',
                                    'Action item is renamed or deleted',
                                  ]}
                                />

                                <Body2 kind="textBody">
                                  When following a list… 
                                </Body2>
                                <List
                                  kind={ListKind.DISC}
                                  items={[
                                    'You’ll get the same notifications listed above but for all action items.',
                                    ' You’ll also get notifications for all newly created action items.',
                                  ]}
                                />
                              </div>
                            )}
                          </div>

                          <Checkbox
                            readOnly={!notif.isChecked}
                            onChange={() =>
                              updateParameters(notif.type as any, category.name)
                            }
                            checked={
                              !notif.isChecked ? false : category.isChecked
                            }
                          />
                        </FlexRowSpaceBetween>
                        <VerticalSpacer size="8px" />
                      </div>
                    ))}
                  </div>
                </NotificationWrapper>
                {idx === options.length - 1 ? null : (
                  <VerticalSpacer size="16px" />
                )}
              </div>
            ))}
          </div>
        </SectionCard>
      </PageLayout>
    </div>
  );
};

export default NotificationSettings;
