import {useSections} from '@hooks/action-items-sections';
import {useStoreContext} from '@store/store-context';
import {Button} from '@ui/atoms/button';
import {MoreVerticalIcon, CancelIcon} from '@ui/atoms/icons';
import {TickIcon} from '@ui/atoms/icons/tick';
import {UserTickIcon} from '@ui/atoms/icons/user-tick';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {Headline2, Body2} from '@ui/atoms/typography';
import {ModalCard} from '@ui/layouts/modal-card';
import {SectionMenu} from '@ui/layouts/section-card/section-toggle';
import {BackAction} from '@ui/molecules/back-action';
import {DropdownVertical} from '@ui/molecules/dropdown-vertical';
import {TextField} from '@ui/molecules/field/textfield';
import {Modal} from '@ui/molecules/modal';
import {ActionItems} from '@ui/organisms/action-items/action-items';
import {CustomIconModal} from '@ui/organisms/modal/custom-icon-modal';
import {FlexRow} from '@ui/style/styles';
import {removeUndefinedFromObject} from '@utils/data-structure-algos';
import {updateFirebaseData} from '@utils/firebase-handler';
import {
  updateActionItemsActivities,
  updateActionItemsFilter,
} from '@utils/firebase-request';
import {ActionType} from '@api/action-items-api/action-items-request';

import {userName} from '@utils/user-name';
import dayjs from 'dayjs';
import {memo, useState} from 'react';
import validator from 'validator';
import {DropdownItem} from '../you/you-page.styles';
import {ModalWrapper} from '../groups/styles';
import {UserAPIRequestImpl} from '@api/user-api/user-api-request';
import {UserAPIController} from '@api/user-api/user-api-controller';
import {filterDuplicateString} from '@utils/array';
import {HoverTooltip} from '@ui/molecules/hover-tooltip';

type Action =
  | 'dueDate'
  | 'user'
  | 're-sort'
  | ''
  | 'remove'
  | 'remove-link'
  | 'add-url';

interface ActionItemsSectionProps {
  getSourceDetail: (sourceId: string) => any;
  goalOptions: any[];
  selectedUser: string;
  actionItems: any[];
  name: string;
  sectionSlug: string;
  sources?: any;
  followers?: string[];
  handleChange: (
    value: ActionType[],
    actionType?: Action,
    fieldId?: string,
    item?: ActionType,
  ) => void;
  setViewSource: any;
  data: any[];
}

export const ActionItemsSections = memo(
  ({
    goalOptions,
    name,
    actionItems = [],
    setViewSource,
    followers = [],
    data,
    sectionSlug,
    selectedUser,
    handleChange,
  }: ActionItemsSectionProps) => {
    const [showAddUrl, setShowAddUrl] = useState('');

    const {
      usersStore: {users},
      authStore: {auth},
    } = useStoreContext();

    // Sections
    const {
      setSectionTitle,
      handleEditSection,
      showRenameSectionModal,
      setRenameSectionModal,
      showDeleteModal,
      setShowDeleteModal,
      handleDeleteSection,
      handleRemoveSubscriber,
      computeSections,
      handleFollowSection,
      sectionTitle,
    } = useSections();

    const [urlFormData, setUrlFormData] = useState({
      title: '',
      url: '',
    });

    const handleUpdateUrlFormData = (field: string, value: string) => {
      setUrlFormData((prev) => ({...prev, [field]: value}));
    };

    const handleSubmitNotificationRequest = async (
      message: string,
      subscribers: string[],
    ) => {
      const request = new UserAPIRequestImpl();

      const controller = new UserAPIController(request);

      await controller.requestUserNotification({
        notification: message,
        subscribers,
      });
    };

    const handleActionItemNotification = async (
      actionItemId: string,
      message: string,
      user?: string,
    ) => {
      const item = getActionItem(actionItemId);

      const subscribers = filterDuplicateString([
        ...followers,
        ...(item?.subscribers || []),
      ]);

      if (subscribers.includes(user || auth.user.id)) {
        handleSubmitNotificationRequest(message, subscribers);
      }
    };

    const handleAddUrl = (fieldId: string) => {
      const updatedItemIndex = actionItems.findIndex(
        (item) => item.key === fieldId,
      );

      actionItems[updatedItemIndex] = {
        ...actionItems[updatedItemIndex],
        meta: urlFormData,
        source: 'url',
      };

      const item = actionItems[updatedItemIndex];

      if (item.firebaseId) {
        handleChange(actionItems, 'add-url', item?.key, item);

        updateFirebaseData(
          `action_items/${item.firebaseId}`,
          removeUndefinedFromObject(item),
        );
      }

      setShowAddUrl('');
      setUrlFormData({title: '', url: ''});
      handleActionItemNotification(
        item.key,
        `_(${userName(auth.user)})_ added a URL link to ${item.text}`,
      );
    };

    const getActionItem = (id: string) =>
      actionItems.find((value: {id: string}) => value.id === id);

    const handleActionItemChange = async (
      value: ActionType[],
      actionType: Action,
      fieldId: string,
      item: ActionType & {key: string},
    ) => {
      const computedItem = {
        ...item,
        assignee:
          actionType === 'user'
            ? item?.assignee
            : item?.assignee || selectedUser,
      };

      if (item?.key) {
        const prevItem = getActionItem(item.key);

        const assignee =
          computedItem.assignee?.id || computedItem.assignee || '';

        const prevAssignee = prevItem?.assignee?.id || prevItem?.assignee || '';

        if (
          assignee &&
          assignee !== auth.user.id &&
          prevAssignee !== assignee
        ) {
          handleSubmitNotificationRequest(
            `_(${userName(auth.user)})_ added you to _(${item.text})_`,
            [assignee],
          );
        }

        if (item.text !== prevItem?.text && prevItem?.text) {
          handleActionItemNotification(
            item.key,
            `_(${userName(auth.user)})_ renamed  _(${prevItem.text})_ to _(${
              item.text
            })_`,
          );
        }
      }

      await handleChange(
        value as any,
        actionType,
        fieldId,
        computedItem as any,
      );

      if (actionType === 're-sort') {
        value.forEach((actionItem) => {
          if (sectionSlug !== actionItem.section_slug) {
            handleActionItemNotification(
              actionItem.key,
              `_(${userName(auth.user)})_ moved _(${
                actionItem.text
              })_ to another list`,
            );
          }
        });
      }
    };
    const handleFollowActionItems = (fieldId: string) => {
      const updatedItemIndex = actionItems.findIndex(
        (item) => item.key === fieldId,
      );

      const actionItemsSubscribers: string[] =
        actionItems[updatedItemIndex]?.subscribers || [];

      const isFollowing = actionItemsSubscribers?.includes(auth.user.id);

      const activeSubscribers = isFollowing
        ? actionItemsSubscribers.filter(
            (subscriber) => subscriber !== auth.user.id,
          )
        : actionItemsSubscribers.length
        ? [...actionItemsSubscribers, auth.user.id]
        : [auth.user.id];

      actionItems[updatedItemIndex] = {
        ...actionItems[updatedItemIndex],
        subscribers: activeSubscribers,
      };

      const item = actionItems[updatedItemIndex];

      if (item.firebaseId) {
        handleChange(actionItems, '', item?.key, item);

        updateFirebaseData(
          `action_items/${item.firebaseId}`,
          removeUndefinedFromObject(item),
        );
      }
    };

    const isSectionFollowed = followers.includes(auth.user.id);

    const isUrlValid = !!urlFormData.url && validator.isURL(urlFormData.url);

    const canEdit = !!sectionSlug;

    const firebaseId = computeSections?.find(
      (section) => section.slug === sectionSlug,
    )?.key;

    return (
      <SectionMenu
        title={name}
        action={
          canEdit ? (
            <FlexRow className="gap-3">
              {isSectionFollowed && (
                <HoverTooltip tooltipText="Following" className="z-10">
                  <UserTickIcon />
                </HoverTooltip>
              )}

              <DropdownVertical
                collapseOnClick
                dropdownWrapperStyle={{
                  right: '0px',
                }}
                menu={(handleClose: () => void) => (
                  <>
                    <DropdownItem
                      onClick={() => {
                        handleClose();
                        setRenameSectionModal(true);
                      }}>
                      Rename
                    </DropdownItem>

                    <DropdownItem
                      onClick={() => {
                        handleClose();
                        setShowDeleteModal(true);
                      }}>
                      Delete
                    </DropdownItem>
                    <DropdownItem
                      style={{display: 'flex'}}
                      className="flex flex-row justify-between items-center"
                      onClick={() => {
                        if (isSectionFollowed) {
                          handleRemoveSubscriber(firebaseId, auth.user.id);

                          return;
                        }
                        handleFollowSection(firebaseId, auth.user.id);
                      }}>
                      {isSectionFollowed ? 'Following' : 'Follow'}{' '}
                      {isSectionFollowed && <TickIcon fill="#5F5F8C" />}
                    </DropdownItem>
                  </>
                )}
                customIcon={<MoreVerticalIcon />}
              />
            </FlexRow>
          ) : null
        }
        count={actionItems.length}
        style={{background: 'white', borderColor: '#E1E1EA'}}>
        <div className="p-6">
          <ActionItems
            showSource
            section={sectionSlug}
            onDueDateChange={(fieldId, date, prevDueDate) => {
              const text = getActionItem(fieldId)?.text;

              if (date)
                handleActionItemNotification(
                  fieldId,
                  `_(${userName(auth.user)})_  ${
                    !prevDueDate && date ? 'added a' : 'update the'
                  } due date of ${dayjs(date).format(
                    'ddd. DD MMM. YYYY',
                  )} to _(${text})_`,
                );
            }}
            onActivityLog={({type, message, actionItem}) => {
              if (type === 'create' && isSectionFollowed) {
                handleActionItemNotification(
                  actionItem,
                  message.replace(
                    `[${auth.user.id}]`,
                    `_(${userName(auth.user)})_`,
                  ),
                );
              }

              if (['complete'].includes(type)) {
                handleActionItemNotification(
                  actionItem,
                  message.replace(
                    `[${auth.user.id}]`,
                    `_(${userName(auth.user)})_`,
                  ),
                );
              }

              updateActionItemsActivities({
                action: message,
                createdBy: auth.user.id,
                section: sectionSlug,
                actionItem,
                createdAt: dayjs().format(),
              });
            }}
            onDrag={() => {
              updateActionItemsFilter(selectedUser, {
                type: 'custom',
              });
            }}
            handleOnRemove={(fieldId) => {
              const text = getActionItem(fieldId)?.text;

              handleActionItemNotification(
                fieldId,
                `_(${userName(auth.user)})_  deleted _(${text})_`,
              );
            }}
            handleViewSource={(data) =>
              data.source !== 'url' ? setViewSource(data) : null
            }
            onHandleDropdownClick={(fieldId, id) => {
              if (id === 'follow') {
                handleFollowActionItems(fieldId);
              }
              if (id === 'url') {
                setShowAddUrl(fieldId);
              }
            }}
            handleLinkGoal={(fieldId, goalId) => {
              const updatedItemIndex = data.findIndex(
                (item) => item.key === fieldId,
              );

              data[updatedItemIndex] = {
                ...data[updatedItemIndex],
                source: 'goal',
                sourceId: goalId,
              };

              let updatedItem = data[updatedItemIndex];

              if (goalId)
                handleActionItemNotification(
                  fieldId,
                  `_(${userName(auth.user)})_ added a goal link to _(${
                    updatedItem.text
                  })_`,
                );

              handleChange(data, '', fieldId, updatedItem);
            }}
            goalOptions={goalOptions}
            canDelete={(fieldId) => {
              const task = actionItems.find((task) => task.key === fieldId);

              if (!!task) {
                // allow admin delete action item
                if (auth.user.role === 'admin' && !!task) {
                  return true;
                }

                // allow manager of task users delete task
                const usersInvolved = users
                  .filter((user) =>
                    [task?.user, task?.updatedBy, task?.assignee].includes(
                      user?.id,
                    ),
                  )
                  .map((user) => user.reviewer?.id);

                if (usersInvolved.includes(auth.user?.id)) {
                  return true;
                }
              }
              return false;
            }}
            users={users.map((user) => ({
              label: userName(user),
              value: user.id,
              avatar: user.avatar,
            }))}
            dropdownComponents={(fieldId: string) =>
              !getActionItem(fieldId)?.source
                ? [
                    {
                      content: `Link with a goal`,
                      id: 'goal',
                    },
                    {
                      content: `Add a URL`,
                      id: 'url',
                    },
                  ]
                : [
                    {
                      content: `Remove link`,
                      id: 'remove-link',
                    },
                  ]
            }
            handleChange={handleActionItemChange as any}
            source=""
            sourceId={'general'}
            value={actionItems}
            userId={auth.user.id}
          />
        </div>

        <Modal open={!!showAddUrl} onClose={() => setShowAddUrl('')}>
          <ModalWrapper>
            <BackAction
              icon={<CancelIcon />}
              title="Close"
              onClick={() => setShowAddUrl('')}
              width="md"
            />
            <VerticalSpacer size="16px" />
            <ModalCard title="">
              <div>
                <Headline2>Add URL </Headline2>

                <VerticalSpacer size="20px" />
                <TextField
                  maxLength={25}
                  label="Title"
                  value={urlFormData.title}
                  placeholder="Enter title (max 25 characters)"
                  onChange={(value) =>
                    handleUpdateUrlFormData('title', value.target.value)
                  }
                  name="name"
                />

                <TextField
                  label="Add URL"
                  value={urlFormData.url}
                  state={isUrlValid || !urlFormData.url ? 'default' : 'error'}
                  helper={isUrlValid || !urlFormData.url ? '' : 'Invalid url'}
                  placeholder="Paste link (use https://)"
                  onChange={(value) =>
                    handleUpdateUrlFormData('url', value.target.value)
                  }
                  name="url"
                />

                <VerticalSpacer size="14px" />

                <Button
                  kind="primary"
                  width="full"
                  onClick={() => handleAddUrl(showAddUrl)}
                  data-form-action={true}
                  disabled={!isUrlValid || !urlFormData.title}>
                  Apply
                </Button>
              </div>
            </ModalCard>
          </ModalWrapper>
        </Modal>
        <Modal
          open={showRenameSectionModal}
          onClose={() => setRenameSectionModal(false)}>
          <ModalWrapper>
            <BackAction
              icon={<CancelIcon />}
              title="Close"
              onClick={() => setRenameSectionModal(false)}
              width="md"
            />
            <VerticalSpacer size="16px" />
            <ModalCard title="">
              <div>
                <Headline2>Rename section </Headline2>

                <VerticalSpacer size="20px" />
                <TextField
                  maxLength={25}
                  label="Title"
                  value={sectionTitle}
                  placeholder="Enter title (max 25 characters)"
                  onChange={(event) => setSectionTitle(event.target.value)}
                  name="name"
                />

                <VerticalSpacer size="14px" />

                <Button
                  kind="primary"
                  width="full"
                  onClick={() =>
                    handleEditSection(firebaseId || '', sectionTitle).then(
                      () => {
                        setSectionTitle('');
                        setRenameSectionModal(false);
                      },
                    )
                  }
                  data-form-action={true}
                  disabled={!sectionTitle || sectionTitle.length > 25}>
                  Create
                </Button>
              </div>
            </ModalCard>
          </ModalWrapper>
        </Modal>

        <CustomIconModal
          open={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          customButton={
            <Button
              kind="secondary"
              width="full"
              onClick={() => handleDeleteSection(firebaseId)}
              type="button">
              Delete
            </Button>
          }
          body={<></>}
          onClick={() => {}}
          title={'Delete Section?'}
          description={
            <div>
              <Body2 align="center">
                Are you sure you want to delete{' '}
                <span className="text-[#585ADF] font-medium text-[16px]">
                  {name}
                </span>
                ? This action cannot be undone.
              </Body2>
            </div>
          }
        />
      </SectionMenu>
    );
  },
);
