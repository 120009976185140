import {getDatabase, push, ref, set, get} from 'firebase/database';
import dayjs from 'dayjs';
import {masteryTasks} from '@pages/dashboard/get-started/mastery-tasks';
import axios from 'axios';
import {authStore} from '../store/stores/auth-store';
import firebaseConfig from './firebase';
import {
  removeFirebaseData,
  setFirebaseData,
  updateFirebaseData,
  writeFirebaseData,
} from './firebase-handler';
export const db = getDatabase(firebaseConfig);

export function writeData(item: any, path: string, id: string) {
  writeFirebaseData(`${path}/comments`, {
    comment: item.comment,
    edited: item.edited,
    commentBy: item.commentBy,
    createdAt: item.createdAt,
    updatedAt: item.updatedAt,
    replies: [],
  }).then((response) => {
    postComment(item, response, id);

    setFirebaseData(`commentsEntities/${response}`, item.rawComment);
  });
}

export const AddSubscribers = async (path: string, item: any) => {
  setFirebaseData(`${path}/subscribers`, item);
};

export const handleCaptureLogin = (
  data: {
    workspaceId: string;
    userId: string;
    userCreatedAt: string;
  },
  action: () => void,
) => {
  const db = getDatabase(firebaseConfig);

  const path = `${FIREBASE_BASE_URL}/${data.workspaceId}/users/${data.userId}/loginEvents/`;

  const storeRedirectComment = localStorage.getItem('unseen_comment');

  // Step 4: Check previous logins
  get(ref(db, path)).then((snapshot) => {
    const loginEvents = snapshot.val();

    // Step 5: Compare timestamps
    const lastLoginTimestamp = loginEvents
      ? (loginEvents as number)
      : Date.parse(data.userCreatedAt);

    const currentTimestamp = Date.now();

    const fourteenDaysInMilliseconds = 14 * 24 * 60 * 60 * 1000;

    if (currentTimestamp - lastLoginTimestamp > fourteenDaysInMilliseconds) {
      action();
      setFirebaseData(`/users/${data.userId}/loginEvents/`, currentTimestamp);
    }
    if (storeRedirectComment) {
      window.location.assign(`${storeRedirectComment}`);
    }
  });
};

export const postComment = async (item: any, key: any, id: any) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/notifications/mentions`,
      {
        comment: item.comment,
        goal: id,
        commentBy: item.commentBy,
        commentPath: `objectives/goals?goal=${id}&insight=true&comment=$${key}`,
        users: item.mention,
      },
      {
        headers: {
          Authorization: `Bearer ${authStore?.auth?.tokens.access.token}`,
        },
      },
    );

    return true;
  } catch (error) {
    console.log(error);
  }
};

export const AddMentions = (
  userId: string,
  id: string,
  data: any,
  mentionsPath: string,
) => {
  updateFirebaseData(`users/${userId}/mentions/${mentionsPath}/${id}`, data);
};

export const Deletedata = (path: string, e: string) => {
  removeFirebaseData(`${path}/comments/${e}`);
};

export const updateData = (e: any, item: any, path: string) => {
  updateFirebaseData(`${path}/comments/${e}`, {
    comment: item.comment,
    edited: item.edited,
    commentBy: item.commentBy,
    createdAt: item.createdAt,
    updatedAt: item.updatedAt,
    replies: [],
  });

  writeFirebaseData(`commentsEntities/${e}`, {
    entities: item.rawComment,
  });
};

export const ccFirebaseReminderTracker = (userId: string, reviewId: string) => {
  setFirebaseData(
    `users/${authStore.auth.user.id}/review_reminder/${reviewId}`,
    {
      [userId]: new Date(
        new Date().setHours(new Date().getHours() + 1),
      ).getTime(),
    },
  );
};

export const checkinReminderTracker = (userId: string) => {
  writeFirebaseData(`checkin/reminder`, {
    managerId: userId,
    lastSent: new Date(
      new Date().setHours(new Date().getHours() + 2),
    ).getTime(),
  });
};

export const checkinResetTracker = () => {
  setFirebaseData(`checkin/reset`, {
    timestamp: new Date().getTime(),
  });
};

export const updateCheckinReminder = (userId: string, id: string) => {
  updateFirebaseData(`checkin/reminder/${id}`, {
    managerId: userId,
    lastSent: new Date(
      new Date().setHours(new Date().getHours() + 2),
    ).getTime(),
  });
};

// Fn handling general errors on the app
export const logError = (data: any) => {
  const db = getDatabase(firebaseConfig);
  const postListRef = ref(
    db,
    `/error/${process.env.REACT_APP_DEV_ENV}/${dayjs().format('YYYY/MMMM/DD')}`,
  );

  const newPostRef = push(postListRef);

  set(newPostRef, {
    url: data?.url || '',
    error: data?.error || '',
    page: data.page,
    workspace: data.workspace,
    loggedInUser: data.loggedInUser,
    timeCreated: data.createdAt,
  });
};

export const updateMasteryTier = (
  userId: string,
  status: {
    tier: string;
  },
) => {
  updateFirebaseData(`users/${userId}/onboarding/mastery/tier`, status.tier);
};

// State managing home page beam mastery widget
export const dismissBeamMastery = (
  userId: string,
  status: {
    isDismissed: boolean;
  },
) => {
  updateFirebaseData(
    `users/${userId}/onboarding/mastery/isDismissed`,
    status.isDismissed,
  );
};

export const updateTooltipStatus = (
  id: string,
  status: {isDismissed: boolean},
) => {
  const userId = authStore?.auth.user.id;

  updateFirebaseData(`users/${userId}/onboarding/tooltip`, {
    [id]: status.isDismissed,
  });
};

export const beamMasteryTasks = (field: string, value: boolean) => {
  const db = getDatabase(firebaseConfig);
  const userId = authStore?.auth.user.id;

  const path = `users/${userId}/onboarding/mastery/tasks/${field}`;
  const wholePath = `users/${userId}/onboarding/mastery/tasks`;

  get(ref(db, path)).then((snapshot) => {
    const event = snapshot.val();

    if (!event) {
      writeFirebaseData(path, value);
    }
  });

  get(ref(db, wholePath)).then((snapshot) => {
    const events = snapshot.val();
    if (!events) {
      return;
    }
    const count = Object.keys(events)?.length + 1;
    if (count === 3) {
      updateMasteryTier(userId, {tier: 'adept'});
    }
    if (count === masteryTasks().length) {
      updateMasteryTier(userId, {tier: 'master'});
    }
  });
};

export const updateCheckinsGoals = (userId: string, data: any) => {
  writeFirebaseData(`users/${userId}/checkin`, data);
};

export const updateActionItemsFilter = async (userId: string, data: any) => {
  setFirebaseData(`users/${userId}/action_items/filter`, data);
};

export const updateActionItemsActivities = async (data: any) => {
  writeFirebaseData(`activities/action-items`, data);
};

// keep track of KPI value last updated at.
export const updateKPITracker = (goalId: string, updatedAt: number) => {
  updateFirebaseData(`goals/${goalId}`, {updatedAt});
};
export const updateReviewFramework = (reviewCycleId: string, data: any) => {
  updateFirebaseData(`cc_reviews/${reviewCycleId}`, {frameworks: data});
};

export const updateGoogleTokens = (
  userId: string,
  data: any,
  removeToken?: boolean,
) => {
  if (removeToken) {
    return removeFirebaseData(`users/${userId}/integration`);
  }

  updateFirebaseData(`users/${userId}/integration`, {
    ...data,
    createdAt: dayjs().format(),
  });
};

export const updateMeetingsGoogleEventId = (
  meetingId: string,
  eventsId: string,
) => {
  updateFirebaseData(`meeting/${meetingId}`, {eventId: eventsId});
};

export const notificationHandler = (userId: string, notification: any) => {
  writeFirebaseData(`users/${userId}/notifications`, notification);
};

export const FIREBASE_BASE_URL = `${process.env.REACT_APP_DEV_ENV}/`;

export const database = getDatabase(firebaseConfig);

export const INSIGHT_PATH = 'insights';
