import {Controller} from 'react-hook-form';
import {VerticalSpacer} from '@ui/atoms/spacer';
import dayjs from 'dayjs';
import {Body2, CTA} from '@ui/atoms/typography';
import {TextField} from '@ui/molecules/field/textfield';
import {Button} from '@ui/atoms/button';
import {useMemo, useState} from 'react';
import {useStoreContext} from '@store/store-context';
import {DatePicker} from '@ui/molecules/date-picker';
import {FormProps} from '.';
import {Label} from '@ui/atoms/label';
import {IAPIUserFormat} from '@hooks';
import {OptionSelectField} from '@ui/molecules/select/option';
import {getDayOfWeekStringFromIndex, getTime} from '@utils/date';
import {capitalize} from '@utils';
import {ManageTags} from './tags';
import {writeFirebaseData} from '@utils/firebase-handler';
import {SelectField} from '@ui/molecules/select/selectfield';
import EmailMultiSelectInput from '@ui/molecules/select/email-multiselect';
import {TimeSelect} from '@ui/atoms/time-select';
import {FlexRow} from '@ui/style/styles';
import {ToggleSwitch} from '@ui/atoms/toggle-switch';
import {GoogleMeetIcon} from '@ui/atoms/icons';

interface IMeetingDetails extends FormProps {
  onSubmit: () => Promise<any>;
  loading: boolean;
  tags?: {tag: string; key: string}[];
  isTeamMeetings: boolean;
}

export const MeetingDetails = ({
  onSubmit,
  control,
  tags,
  handleSubmit,
  isTeamMeetings,
  loading,
  values,
  error,
  handleFormValueChange,
}: IMeetingDetails) => {
  const {
    usersStore: {users},
    authStore: {auth},
  } = useStoreContext();

  const enableNextButton =
    values.time && values.startDate && values.frequency && values.title;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <MeetingDetailsForm
        control={control}
        isTeamMeetings={isTeamMeetings}
        tags={tags}
        values={values}
        handleSubmit={handleSubmit}
        error={error}
        handleFormValueChange={handleFormValueChange}
        users={users}
        auth={auth}
      />

      <Button
        width="full"
        onClick={onSubmit}
        disabled={!enableNextButton}
        isLoading={loading}>
        Create meeting
      </Button>
    </form>
  );
};

interface IMeetingDetailsProps extends FormProps {
  users: IAPIUserFormat[]; // Pass users as a prop
  auth: any; // Pass auth as a prop
  isTeamMeetings: boolean;
  tags?: {tag: string; key: string}[];
}

const MeetingDetailsForm = ({
  control,
  handleSubmit,
  isTeamMeetings,
  values,
  tags,
  users,
  auth,
  error,
  handleFormValueChange,
}: IMeetingDetailsProps) => {
  const MEETING_WITH = () => {
    const computeValue = (user: {
      firstName: string;
      lastName: string;
      email: string;
      avatar: {url: string};
      id: string;
    }) => ({
      label:
        user && user.firstName
          ? user.firstName + ' ' + user.lastName
          : user.email + ' (pending invitation)',
      value: user.id,
      avatar: user.avatar?.url,
    });

    return users
      .filter((user) => user.id !== auth.user.id)
      .map((user) => {
        return computeValue(user);
      });
  };

  const computedStartTime = getTime(values.time);
  const computedEndTime = getTime(values.endTime);

  const [selectedTime, setSelectedTime] = useState({
    starts: {
      time: `${
        computedStartTime.targetHour ? computedStartTime.targetHour + ':' : ''
      }${computedStartTime.targetMinute || ''}`,

      period: computedStartTime.targetPeriod || 'AM',
      error: '',
    },
    ends: {
      time: `${
        computedEndTime.targetHour ? computedEndTime.targetHour + ':' : ''
      }${computedEndTime.targetMinute || ''}`,
      period: computedStartTime.targetPeriod || 'AM',

      error: '',
    },
  });

  const updateSelectedTime = (
    value: string,
    field: keyof typeof selectedTime['starts'],
    type: 'starts' | 'ends',
  ) => {
    if (field === 'error') {
      return setSelectedTime((prev) => ({
        ...prev,
        [type]: {...prev[type], error: value},
      }));
    } else {
      setSelectedTime((prev) => ({
        ...prev,
        [type]: {...prev[type], [field]: value, error: ''},
      }));
    }

    const getSelectedTime = {
      ...selectedTime,
      [type]: {...selectedTime[type], [field]: value},
    };

    handleFormValueChange(
      `${getSelectedTime[type].time} ${getSelectedTime[type].period}`,
      type === 'starts' ? 'time' : 'endTime',
    );
  };

  const getWeekFromIndex = (week: number) => {
    switch (week) {
      case 1:
        return 'first';
      case 2:
        return 'second';
      case 3:
        return 'third';
      case 4:
        return 'fourth';

      default:
        return 'fifth';
    }
  };

  const computeFrequencyOptions = useMemo(() => {
    const getDate = dayjs(values.startDate).day();

    const startOfMonth = dayjs(values.startDate).startOf('month');

    const startOfWeek = startOfMonth.startOf('week');

    const diff = dayjs(values.startDate).diff(startOfWeek, 'days');

    const getWeek = Math.ceil((diff + 1) / 7);

    const dayOfWeek = getDayOfWeekStringFromIndex(getDate);

    const options = [
      {
        value: 'once',
        label: 'Just once',
      },
      {
        value: 'daily',
        label: 'Daily',
      },
    ];

    if (values.startDate) {
      options.push(
        {
          value: 'weekly',
          label: `Weekly on ${capitalize(dayOfWeek)}`,
        },
        {
          value: 'bi-weekly',
          label: `Bi-weekly on ${capitalize(dayOfWeek)}`,
        },
        {
          value: 'monthly',
          label: `Monthly on the ${getWeekFromIndex(getWeek)} ${capitalize(
            dayOfWeek,
          )}`,
        },
      );
    }
    return options;
  }, [values.startDate]);

  const [manageTags, setManageTags] = useState(false);

  return (
    <>
      <Label>What's the topic of this meeting?</Label>
      <VerticalSpacer size="8px" />
      <Controller
        name="title"
        control={control}
        render={({onBlur, value}) => (
          <TextField
            onBlur={onBlur}
            inputStyle={{borderRadius: '10px'}}
            value={values.title}
            placeholder="e.g. Weekly catch up "
            onChange={(event) => {
              handleFormValueChange(event.target.value, 'title');
            }}
          />
        )}
      />
      {isTeamMeetings && (
        <div className="relative">
          <Label>
            Tags <span className="text-[#5F5F8C] font-normal ">(optional)</span>
          </Label>
          <VerticalSpacer size="8px" />

          <SelectField
            placeholder="# - add tag"
            // value={value}
            performance
            type="dropdownOnly"
            excludeSearchBox
            showCancelIcon={false}
            borderRadius="10px"
            defaultValue={values.label?.map((tag) => {
              return {value: tag};
            })}
            noOptionMessage={'No option Found'}
            maxSelection={1}
            groupDisplayLength={3}
            onClickCustomOption={() => {
              setManageTags(true);
            }}
            customOption
            customOptionName="Manage tags"
            options={[
              ...(tags
                ? tags.map((tag) => ({value: tag.tag, label: tag.tag}))
                : []),
              {
                label: 'Custom',
                value: 'custom-option',
              },
            ]}
            onChange={(data: {value: string; label: any | null}) => {
              if (data?.value.toLowerCase() === 'custom') return;

              handleFormValueChange(
                data?.value ? [data?.value] : [],

                'label',
              );
            }}
          />
        </div>
      )}

      <Label>Who will be joining this meeting?</Label>
      <Controller
        name="participant"
        control={control}
        render={({onBlur, value}) => (
          <EmailMultiSelectInput
            options={MEETING_WITH()}
            onChange={(data) => {
              const emailParticipant = data.filter((option) => option.isEmail);

              const idParticipant = data.filter((option) => !option.isEmail);

              handleFormValueChange(
                idParticipant?.map((option) => option.value),
                'participant',
              );

              handleFormValueChange(
                emailParticipant?.map((option) => option.value),
                'emailParticipant',
              );

              return;
            }}
          />
        )}
      />
      <VerticalSpacer size="16px" />

      <Label>At what date?</Label>
      <VerticalSpacer size="8px" />
      <Controller
        name="startDate"
        control={control}
        render={({onBlur, value}) => (
          <DatePicker
            label={'Date'}
            dateAlign="left"
            placeholder={values.startDate ? '' : 'Choose'}
            onChange={(date) => handleFormValueChange(date, 'startDate')}
            value={values.startDate}
            disabledBefore={dayjs().subtract(1, 'day').format()}
          />
        )}
      />
      <VerticalSpacer size="24px" />
      <Label>At what time?</Label>
      <Controller
        name="time"
        control={control}
        render={() => (
          <>
            <div className="flex sm:flex-col flex-row  gap-3">
              <div className="sm:w-full w-[50%]">
                <TimeSelect
                  selectedTime={selectedTime.starts}
                  type="starts"
                  addEndTime={!selectedTime?.ends.time}
                  updateSelectedTime={updateSelectedTime}
                />
                {selectedTime.starts.error ? (
                  <Body2
                    kind="red400"
                    style={{fontSize: '12px'}}
                    weight="semibold">
                    {selectedTime.starts.error}
                  </Body2>
                ) : null}
              </div>

              <div className=" sm:w-full w-[50%]">
                <TimeSelect
                  selectedTime={selectedTime.ends}
                  type="ends"
                  updateSelectedTime={updateSelectedTime}
                />
                {selectedTime.ends.error ? (
                  <Body2
                    kind="red400"
                    style={{fontSize: '12px'}}
                    weight="semibold">
                    {selectedTime.ends.error}
                  </Body2>
                ) : null}
              </div>
            </div>
          </>
        )}
      />
      <VerticalSpacer size="24px" />
      <Label>How often should it hold?</Label>
      <VerticalSpacer size="8px" />
      <Controller
        name="frequency"
        control={control}
        render={({onBlur, value}) => (
          <OptionSelectField
            name="user"
            inputStyle={{borderRadius: '10px'}}
            value={values.frequency}
            excludeSearchBox
            showCancelIcon={values.frequency !== 'once'}
            // disabled={!!id}
            helper={error.frequency?.message || ''}
            state={!!error.frequency ? 'error' : 'default'}
            onChange={(data: {value: string}) => {
              if (!data?.value)
                return handleFormValueChange('once', 'frequency');

              handleFormValueChange(data?.value, 'frequency');
            }}
            borderRadius="10px"
            placeholder="Select frequency"
            options={computeFrequencyOptions}
            fieldNotFoundPlaceHolder={(searchTerm?: string) =>
              `Oops! Seems there is no ${searchTerm} found in this workspace`
            }
          />
        )}
      />

      <Controller
        name="reminder"
        control={control}
        render={({onBlur, value}) => (
          <div className="rounded-[10px] p-6 mb-4 border border-borderLight">
            <Body2 weight="bold" className="mb-2">
              Auto record & Summarise
            </Body2>
            <Body2 kind="textBody">
              Set the BeamAI to join, record and summarise your meetings
              automatically.
            </Body2>
            <FlexRow className="border border-borderDark rounded-[10px] p-4 gap-2 mt-2">
              <ToggleSwitch
                checked={values.enableBeamAI}
                onChange={() => {
                  handleFormValueChange(!values.enableBeamAI, 'enableBeamAI');
                }}
                switchId={Math.random()}
              />
              <CTA>Invite BeamAI to join</CTA>
            </FlexRow>

            <FlexRow className="border-t border-t-borderLight gap-2 mt-2 pt-2">
              <GoogleMeetIcon />
              <Body2 weight="bold">Google Meet</Body2>
            </FlexRow>
          </div>
        )}
      />

      {manageTags && (
        <ManageTags
          open={manageTags}
          onClose={() => setManageTags(false)}
          tags={tags}
          onSubmit={(tag) => {
            writeFirebaseData(`tags`, tag);
          }}
        />
      )}
    </>
  );
};
