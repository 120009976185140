import {ProgressType} from '../ui/interface';

import {ReactNode} from 'react';

export type ConstantType<T extends string> = Record<T, string>;

export enum ActionState {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  ERROR = 'ERROR',
}
export enum BillingType {
  FREE = 'free_tier',
  PREMIUM = 'paid_tier',
  LIFETIME = 'lifetime',
  YEARLY = 'yearly',
  MONTHLY = 'monthly',
}

export enum StatusType {
  INPROGRESS = 'in_progress',
  COMPLETED = 'completed',
  INCOMPLETED = 'in_completed',
  PENDING = 'pending',
}

export enum MeasurementType {
  PERCENTAGE = 'PERCENTAGE',
  BINARY = 'BINARY',
  NUMERIC = 'NUMERIC',
  CURRENCY = 'CURRENCY',
}

export enum CurrencyType {
  NAIRA = '₦',
  DOLLAR = '$',
  EURO = '€',
  POUND = '£',
}

export enum MemberRole {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  USER = 'USER',
  OWNER = 'OWNER',
}

export interface ProgressCurrencyType {
  target: number;
  current: number;
  start: number;
  currency: CurrencyType;
}

export interface ProgressBooleanType {
  state: boolean;
}

export interface ProgressNumericType {
  target: number;
  start: number;
  current: number;
}

export interface ProgressPercentageType {
  current: number;
  start: number;
  target: number;
}

export type ProgressState =
  | ProgressCurrencyType
  | ProgressBooleanType
  | ProgressNumericType
  | ProgressPercentageType;

export type IProgressState<T extends ProgressState> = T & {
  completed: boolean;
};

export type InputState = 'error' | 'default';

export enum MoodType {
  AWFUL = 'AWFUL',
  UNHAPPY = 'UNHAPPY',
  JUSTTHERE = 'JUST_THERE',
  HAPPY = 'HAPPY',
  FIREDUP = 'FIRED_UP',
}

export enum TodoType {
  CHECKIN = 'CHECKIN',
  ONBOARDING = 'ONBOARDING',
}

export enum PositionType {
  CEO = 'CEO',
  CTO = 'CTO',
  TEAM_LEAD = 'TEAM_LEAD',
  HUMAN_RESOURCES = 'HUMAN_RESOURCES',
  OTHER = 'OTHER',
}

export enum GoalKind {
  INDIVIDUAL = 'INDIVIDUAL',
  OVERARCHING = 'Over-arching',
  COMPANY = 'COMPANY',
  TEAM = 'TEAM',
  GROUP = 'GROUP',
}

export enum TransitionType {
  NONE = 'NONE',
  FADE_IN_RIGHT = 'FADE_IN_RIGHT',
  FADE_IN_LEFT = 'FADE_IN_LEFT',
  FADE_OUT_RIGHT = 'FADE_OUT_RIGHT',
  FADE_OUT_LEFT = 'FADE_OUT_LEFT',
}
export enum WorkspaceUseCase {
  ALL = 'all',
  PERFORMANCE = 'performance',
  ENGAGEMENT = 'engagement',
}
export interface TKeyResult {
  id?: string;
  name: string;
  user: string;
  activities?: any[];
  measurement: {unit: string; symbol?: string};
  range?: {
    target?: number;
    start?: number;
    current?: number;
    hubspotValue?: number;
    currency?: string;
    stretch?: number;
    good?: number;
  } | null;
  showSource?: boolean;
  hubspotValue?: number;
  saveSource?: boolean;
  currentValue?: number;
  source?: {
    app: string;
    type: string;
    keyId: string;
    value: number;
  } | null;
}
export interface IKeyResult {
  id?: string;
  name: string;
  user: string;
  measurement: {unit: MeasurementType; symbol?: string};
  goalInfo: {
    id: string;
    name: string;
    startDate: string;
    endDate: string;
    percentage: number;
    value: number;
  };
  percentageProgress: number;
  performance: string;
  range?: {
    target?: number;
    start?: number;
    current?: number;
    hubspotValue?: number;
    currency?: string;
    stretch?: number;
    good?: number;
  } | null;
  startValue: number;
  targetValue: number;
  currentValue: number;
  source?: {
    app: string;
    type: string;
    keyId: string;
    value: number;
  } | null;
}

export interface IUser {
  name: string;
  src: string;
}
interface User {
  name: string;
  src: string;
}
export interface Metric {
  title: string;
  slug: string;
  user: {
    name: string;
    avatar: string;
    id: string;
  };
  activities?: any[];
  state: string;
  measurement: MeasurementType;
  percent: number;
  id: string;
  startValue: number;
  currentValue: number;
  targetValue: number;
}
export type TGoalResponse = {
  id?: string;
  name: string;
  measurement: {unit: string; symbol?: string; preference?: string};
  keyResults: TKeyResult[];
  date: {starts: string; ends: string};
  goalType: string;
  goalAlignment: any;
  groupType?: string;
  currentValue?: string;
  assignee?: any;
  isKpi?: boolean;
  meta?: {
    tags: Array<string>;
    description: string;
  };
  group?: string;
  status?: string;
  manager: string;
  range: {target?: number; start?: number; current?: number};
};

export interface GoalIncludingAlignment extends TGoalResponse {
  alignedGoals: TGoalResponse[];
}
export interface IGoalResult {
  goalAlignment: boolean;
  alignedGoals: TGoalResponse[];
  createdAt: string;
  activities: any[];
  id: string;
  measurement: {
    unit: MeasurementType;
    preference?: 'sum' | 'average' | 'latest';
    symbol?: string;
  };
  currencySymbol?: any;
  assignee: {
    id: string;
    name: string;
  };
  startDate: string;
  endDate: string;
  createdBy: string;
  completedAt?: string;
  updatedAt?: string;
  performance: string;
  progress: {
    state: string;
    type: ProgressType;
    percent: number;
    due: string;
    status: string;
    endDate: string;
  };
  name: string;
  goalType: string;
  isKpi?: boolean;
  totalUsers: number;
  status?: string;
  type: GoalKind;
  // users: IUser[]; //TODO - ADDRESS TS ERRS
  users: any;
  keyResults: Metric[];
  currentValue?: number;
  targetValue?: number;
}

/* export interface Member {
  jobtitle: string;
  emailaddress: string;
  id: string;
  permission: string;
  button: ReactNode;
  user: IUser[];
} */
export interface IUserResult {
  members: [
    {
      jobtitle: string;
      emailaddress: string;
      id: string;
      permission: string;
      button: ReactNode;
    },
  ];
  user: User;
}

export interface IFetchGoalsResponse {
  message: string;
  statusCode: number;
  result: IGoalResult[];
}

export interface IResultArray {
  id?: string;
  name: string;
  measurement: {unit: string; symbol?: string};
  assignee: string;
  startValue: number | undefined;
  targetValue: number | undefined;
  currentValue: number | undefined;
  source: any;
  goodValue?: number | undefined;
  stretchValue?: number | undefined;
  currency?: string;
}

export interface ICreateGoalApiDataFormat {
  id?: string;
  name: string;
  measurement: {unit: string; symbol?: string; preference?: string};
  assignee: string;
  startDate: string;
  endDate: string;
  performance?: string;
  startValue?: number;
  targetValue?: number;
  meta?: {tags?: string[]; description?: string};
  currency?: string;
  keyResults: IResultArray[];
  currentValue?: number;
  goalType?: string;
  group?: string;
  goalAlignment?: string;
}
export interface IWorkspaceApiDataFormat {
  id?: string;
  name: string;
  contactEmail: string;
  timezone: string;
}
export interface IAPIGroupFormat {
  groupType: {id: string; name: string};
  id: string;
  group: string;
  isDefault: boolean;
  members: any[];
  membersCount: number;
  name: string;
}
export interface IAPIUserFormat {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  emailVerified: boolean;
  status: string;
  deletedAt?: string;
  workspace: string;
  jobTitle: string;
  isReviewer: boolean;
  phoneNumber: string;
  reviewer: string | any;
  groups?: IAPIGroupFormat[];
  reporting: {
    schedule: string;
    weekDay: string;
  };
  avatar: {
    url: string;
  };
}

export interface IDropdownUser {
  label: {
    avatar: {
      name: string;

      src: string;
    };
    id: string;
    name: string;
  };
  value: string;
}
export interface IMembersFormatFilter {
  label: string;
  value: string;
}

export interface IGoalActivities {
  goalActivities: [
    {
      startDate: string;
      endDate: string;
      atRisk: 0;
      onTrack: 0;
      behind: 0;
      total: 0;
    },
    {
      startDate: string;
      endDate: string;
      atRisk: 0;
      onTrack: 0;
      behind: 0;
      total: 0;
    },
    {
      startDate: string;
      endDate: string;
      atRisk: 0;
      onTrack: 0;
      behind: 0;
      total: 0;
    },
    {
      startDate: string;
      endDate: string;
      atRisk: 0;
      onTrack: 0;
      behind: 0;
      total: 0;
    },
    {
      startDate: string;
      endDate: string;
      atRisk: 0;
      onTrack: 0;
      behind: 0;
      total: 0;
    },
  ];
}

export interface IGoalsOwnership {
  goalsOwnership: [
    {
      startDate: string;
      endDate: string;
      withGoals: 0;
      withoutGoals: 0;
    },
    {
      startDate: string;
      endDate: string;
      withGoals: 0;
      withoutGoals: 0;
    },
    {
      startDate: string;
      endDate: string;
      withGoals: 0;
      withoutGoals: 0;
    },
    {
      startDate: string;
      endDate: string;
      withGoals: 0;
      withoutGoals: 0;
    },
    {
      startDate: string;
      endDate: string;
      withGoals: 0;
      withoutGoals: 0;
    },
  ];
}

export interface IKeyResultsOwnership {
  keyResultsOwnership: [
    {
      startDate: string;
      endDate: string;
      withKeyResults: 0;
      withoutKeyResults: 0;
    },
    {
      startDate: string;
      endDate: string;
      withKeyResults: 0;
      withoutKeyResults: 0;
    },
    {
      startDate: string;
      endDate: string;
      withKeyResults: 0;
      withoutKeyResults: 0;
    },
    {
      startDate: string;
      endDate: string;
      withKeyResults: 0;
      withoutKeyResults: 0;
    },
    {
      startDate: string;
      endDate: string;
      withKeyResults: 0;
      withoutKeyResults: 0;
    },
  ];
}

export interface IGroupType {
  isDefault: string;
  name: string;
  workspace: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  id: string;
  groupsCount: number;
  membersCount: number;
}

export interface IGroup {
  isDefault: string;
  name: string;
  groupAdmin: string;
  groupType: string;
  workspace: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  id: string;
  membersCount: number;
  goalsCount: number;
}

export interface IGroupMembers {
  isDefault: string;
  name: string;
  groupType: {name: string; id: string};
  membersCount: number;
  id: string;
  disabled?: boolean;
  members: [];
}

export interface IMeetingSummary extends IAPIUserFormat {
  meetingData: {
    upcoming: number;
    completed: number;
    active: number;
    missed: number;
    canceled: number;
    nextMeeting: string;
  };
}
interface AgendaTemplate {
  title: string;
  agenda: string[];
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  id: string;
}

export interface AgendaTemplateResponse {
  results: AgendaTemplate[];
  page: number;
  limit: number;
  totalPages: number;
  totalResults: number;
}

export interface IMeetingResponse {
  eventId: any;
  meetingLink: any;
  addBeamAI: boolean | undefined;
  emailParticipant: string[];
  googleEvent: any;
  type: 'team' | '1:1';
  hostAgenda: {text: string; completed: boolean; _id: string}[];
  participantAgenda: {text: string; completed: boolean; _id: string}[];
  createdAt: string;
  extraParticipant?: {email: string}[];
  createdBy: string;
  frequency: string;
  id: string;
  label: string[];
  meta: {
    botId?: string;
    calendarEventId: string;
  };
  meetingCode: string;
  updatedBy: string;
  nextSteps: [];
  endDate?: string;
  reasonForCancellation?: string;
  participant: any;
  reminder: string[];
  shareAgenda: boolean;
  title?: string;
  shareHostAgenda: boolean;
  status: string;
  hostNote: string;
  shareHostNote: boolean;
  shareParticipantNote: boolean;
  feedback: string;
  participantNote: string;
  shareParticipantAgenda: string[];
  shareNextStep: boolean;
  shareNote: boolean;
  startDate: string;
  time: string;
  updatedAt: string;
  user: IAPIUserFormat;
  workspace: string;
}

interface AdditionalNote {
  note: string;
}

interface AgendaItem {
  text: string;
  status: 'pending' | 'completed';
}

interface MeetingDetails {
  date?: string;
  time?: string;
  location?: string;
  participants?: string;
}

export interface MeetingBotResponse {
  additionalNotes: AdditionalNote[];
  meetingId: string;
  workspace: string;
  createdBy: string;
  discussion: string;
  nextSteps: string[];
  agenda: AgendaItem[];
  actionItems: AgendaItem[];
  decisionsMade: AgendaItem[];
  transcript: string;
  summary: string;
  meetingDetails: MeetingDetails;
  createdAt: string;
  updatedAt: string;
  id: string;
}
