import {CreateMeetingValidator} from './create-meeting-validator';
import {useForm, Resolver} from 'react-hook-form';
import {useMemo, useCallback, useState, useEffect} from 'react';
import {MeetingRequestImpl} from '../meeting.request';
import {useLocation, useNavigate} from 'react-router';
import {MeetingController} from '../meeting-controller';
import {useStoreContext} from '@store/store-context';
import {yupResolver} from '@hookform/resolvers/yup';
import {activateNotification} from '@ui/molecules/notification/activate-notification';
import {
  beamMasteryTasks,
  updateMeetingsGoogleEventId,
} from '@utils/firebase-request';
import {useSearchParams} from 'react-router-dom';
import {useGCalenderHook} from '@hooks/gcalender';
import dayjs from 'dayjs';
import {splitTimeFormat} from '@utils/date';

export interface CreateMeetingFormData {
  participant: string[];
  title: string;
  startDate: string;
  frequency: string;
  enableBeamAI?: boolean;
  emailParticipant: string[];
  endTime: string;
  label: string[];
  time: string;
  reminder: string[];
}

export const useCreateMeetingHook = () => {
  const [searchParams] = useSearchParams();
  const participant = searchParams.get('participant');
  const isTeamMeetings = true;

  const allParticipant = participant ? participant?.split(',') : [];
  const {
    handleSubmit,
    errors,
    formState,
    setValue,
    control,
    watch,
  } = useForm<CreateMeetingFormData>({
    resolver: yupResolver(CreateMeetingValidator.createMeeting) as Resolver<
      CreateMeetingFormData,
      object
    >,
    mode: 'all',
    defaultValues: {
      participant: allParticipant,
      title: '',
      startDate: '',
      frequency: 'once',
      time: '',
      endTime: '',
      reminder: ['24'],
    },
  });

  const {createEvent, isSigned} = useGCalenderHook();

  const {
    usersStore: {users},
  } = useStoreContext();

  const [values, setValues] = useState<CreateMeetingFormData>({
    participant: allParticipant,
    title: '',
    label: [],
    startDate: '',
    frequency: 'once',
    emailParticipant: [],
    endTime: '',
    time: '',
    reminder: ['24'],
    enableBeamAI: true,
  });

  const {
    authStore: {auth},
  } = useStoreContext();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const isSubmitting = useMemo(() => {
    return formState.isSubmitting;
  }, [formState]);

  const handleFormValueChange = useCallback(
    async (value: string | null | string[] | boolean, name: string) => {
      setValue(name, value);

      setValues((prev) => ({...prev, [name]: value}));
    },
    [setValue],
  );

  const isDuplicate = searchParams.get('action') === 'duplicate';

  const {state} = useLocation() as {state: {agenda: any}};

  const parseValue = (value: string) => {
    try {
      return JSON.parse(value);
    } catch (error) {
      return value;
    }
  };

  const stateToFormValueMap = useMemo(
    () => ({
      agenda: 'hostAgenda',
      label: 'label',
      participant: 'participant',
      title: 'title',
    }),
    [],
  );

  useEffect(() => {
    if (isDuplicate && state) {
      Object.entries(stateToFormValueMap).forEach(
        ([stateKey, formValueKey]) => {
          const stateValue = (state as any)[stateKey];
          if (stateValue) {
            const parsedValue = parseValue(stateValue);
            handleFormValueChange(parsedValue, formValueKey);
          }
        },
      );
    }
  }, [isDuplicate, state, handleFormValueChange, stateToFormValueMap]);

  const request = new MeetingRequestImpl();

  const controller = new MeetingController(request);
  const tagsPath = `tags`;

  const onSubmit = async () => {
    setLoading(true);

    const {targetHour, targetMinute, targetPeriod} = splitTimeFormat(
      values.time,
    );

    const endTime = splitTimeFormat(values.endTime);

    const computeTimeWithDate = dayjs(values.startDate)
      .set('hour', targetHour)
      .set('minute', targetMinute);

    const computeEndTimeWithDate = dayjs(values.startDate)
      .set('hour', endTime.targetHour)
      .set('minute', endTime.targetMinute);

    const _data = {
      ...values,
      hostAgendaPrivate: undefined,
      shareHostAgenda: true,
      startDate: computeTimeWithDate.format(),
      shareParticipantAgenda: undefined,
      endDate: computeEndTimeWithDate.format(),
      enableBeamAI: undefined,
      endTime: undefined,
    };

    const response = await controller.postMeeting(_data);

    if (response) {
      const participantData = users.filter((user) =>
        values.participant.includes(user.id),
      );

      if (isSigned && values.enableBeamAI) {
        const replacePeriod = (date: string, period: string) => {
          return date.replace(
            date.slice(date.length - 2),
            targetPeriod.toLowerCase(),
          );
        };

        const updatedDate = dayjs(
          replacePeriod(
            computeTimeWithDate.format(`YYYY/MM/DD hh:mm a`),
            targetPeriod,
          ),
        ).format();

        const endDate = dayjs(
          replacePeriod(
            computeEndTimeWithDate.format(`YYYY/MM/DD hh:mm a`),
            endTime.targetPeriod,
          ),
        ).format();

        const updatedEndDate = endDate;

        const eventObject: any = {
          start: {dateTime: updatedDate, timeZone: auth.user.timezone},
          end: {dateTime: updatedEndDate, timeZone: auth.user.timezone},
          summary: values.title,
          description: '',
          attendees: [
            ...values.emailParticipant.map((participant) => ({
              email: participant,
              organizer: false,
              self: false,
              responseStatus: 'needsAction',
            })),
            ...participantData.map((participant) => ({
              email: participant?.email,
              organizer: false,
              self: false,
              responseStatus: 'needsAction',
            })),
          ],
        };

        if (values.enableBeamAI) {
          await createEvent(eventObject, 'primary', 'all').then(
            function (resp: any) {
              updateMeetingsGoogleEventId(response.id, resp.result.id);

              controller.patchMeeting(
                {
                  meta: {
                    calendarEventId: resp.result.id,
                  },
                },
                response.id,
              );
            },
            function (reason: any) {},
          );
        }
      }
      if (auth.user.role === 'user' && !auth.user.isReviewer) {
        beamMasteryTasks('schedule_meeting', true);
      }
      activateNotification({
        content: 'Meeting scheduled.',
        title: 'Success',
        kind: 'success',
      });
      setLoading(false);

      navigate(
        `/view-meetings/${response.id}?${isTeamMeetings ? 'type=teams' : ''}${
          participantData?.length === 1 &&
          participantData?.[0].reviewer?.id === auth.user.id
            ? 'manager=true'
            : ''
        }`,
      );
    }
    setLoading(false);
  };
  return {
    handleFormValueChange,
    isSubmitting,
    control,
    loading,
    onSubmit,
    values,
    isTeamMeetings,
    isDuplicate,
    handleSubmit,
    tagsPath,
    errors,
    watch,
  };
};
